jQuery(document).ready(function($) {
	/* Mobile Menu */
	mobileNavigationMenuSelector = ".mobile-navigation-menu"
	menu = new Mmenu(mobileNavigationMenuSelector, {
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true,
		}
	});

	api = menu.API;
	$(".mobile-menu-button").click(function() {
		if ($(mobileNavigationMenuSelector).hasClass("mm-menu_opened")) {
			api.close();
		}
		else {
			api.open();
		}
	});

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .main-nav-link").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".dropdown-wrapper").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".dropdown-wrapper").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	// Remove underline from link images
	$('img').parent('a').css('border-bottom', '0px');

	/* eslint-disable */
	/* Webflow */
	Webflow.require('ix').init([
	  {"slug":"dropdown-hover","name":"Dropdown Hover","value":{"style":{},"triggers":[{"type":"hover","selector":".dropdown-list","descend":true,"stepsA":[{"opacity":1,"height":"auto","transition":"opacity 300ms ease 0ms, height 300ms ease 0ms"}],"stepsB":[{"opacity":0,"height":"0px","transition":"opacity 300ms ease 0ms, height 300ms ease 0ms"}]}]}},
	  {"slug":"cta-animation","name":"CTA Animation","value":{"style":{},"triggers":[{"type":"hover","selector":".cta-content","descend":true,"preserve3d":true,"stepsA":[{"opacity":1,"height":"auto","transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms, height 500ms ease 0ms","x":"0px","y":"0px","z":"0px"}],"stepsB":[{"opacity":0,"height":"0px","transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms, height 500ms ease 0ms","x":"0px","y":"20px","z":"0px"}]},{"type":"hover","selector":".cb-heading","descend":true,"preserve3d":true,"stepsA":[{"opacity":1,"transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms","x":"0px","y":"0px","z":"0px"}],"stepsB":[{"opacity":0.8,"transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms","x":"0px","y":"33px","z":"0px"}]}]}},
	  {"slug":"left-nav-arrow-animation","name":"Left Nav Arrow Animation","value":{"style":{},"triggers":[{"type":"hover","stepsA":[],"stepsB":[]}]}}
	]);
	/* eslint-enable */

	$(window).scroll(function() {
		$('.header-section').toggleClass("sticky", $(this).scrollTop() > 1);
	});
});

jQuery.fn.focusCursorAtEnd = function() {
	tmpValue = jQuery(this).val();
	if (tmpValue != undefined) {
		jQuery(this)
			.val("")
			.focus()
			.val(tmpValue);
	}
};

jQuery.fn.formatNumber = function() {
	var strValue = this.val().replace(/,/g, '');
	var rgx = /(\d+)(\d{3})/;

	while (rgx.test(strValue)) {
		strValue = strValue.replace(rgx, '$1' + ',' + '$2');
	}

	this.val(strValue);

	return this;
};

;(function($) {
	$(function() {
		Processing = {};

		Processing.hide = function() {
			$('#processing').remove();
		};

		Processing.show = function() {
			var container = $('<div id="processing">');

			container.css({
				background: 'none',
				cursor: 'wait',
				height: Math.max($(document).height(),$(window).height()),
				left: 0,
				opacity: 1.00,
				position: 'absolute',
				top: 0,
				width: Math.max($(document).width(),$(window).width())
			});

			$('body').append(container);
		};

		GridOverlay = {
			objOverlay: $('#grid-overlay'),
			objOverlayText: $('#grid-overlay span'),
			objGridBody: $('#grid tbody')
		};

		GridOverlay.hide = function() {
			this.objGridBody.css({
				color: '#000000'
			});

			Processing.hide();
			this.objOverlay.hide();
		};

		GridOverlay.show = function() {
			Processing.show();

			this.objGridBody.css({
				color: '#999999'
			});

			this.objOverlay.show().css({
				width: this.objGridBody.outerWidth(),
				height: this.objGridBody.outerHeight(),
				top: this.objGridBody.position().top,
				left: this.objGridBody.position().left
			});

			intTDPadding = this.objGridBody.find('td').first().css('padding-top').replace(/[^\d]/g, '');
			this.objOverlayText.css('margin-top', this.objGridBody.outerHeight() / 2 - intTDPadding);
		};
	});
})(jQuery);
